import React, { memo, useEffect, useState } from 'react';
import { DoctChip, DoctFreeSoloSearchInput, DoctRadioGroup, DoctTextField } from '@doct-react/app';
import { DoctIcon, DoctTypography } from '@doct-react/core';
import { LocationField, MobileWhatsAppForm } from '../../../../shared';
import { filedManageAttendee } from './field.ManageAttendee';
import { getSpecialtyNames } from '../../CreateEvent/components/Steps/Registration/services/EventRegistrationSectionService';

function FormManageAttendees({
  control,
  errors,
  touched,
  setValue,
  register,
  watch,
  clearErrors,
  defaultValue,
}) {
  const formProps = { control, isErrors: errors, touched, watch, setValue, clearErrors };
  const [specialtyVal, setSpecialtyVal] = useState(null);
  const [specialityArray, setSpecialityArray] = useState(
    watch(filedManageAttendee.specialty.name) || [],
  );
  const [isSpecialtyEmpty, setIsSpecialtyEmpty] = useState(false);

  const [specialtySearchText, setSpecialtySearchText] = useState();
  const [specialtyDataOption, setSpecialtyDataOption] = useState();

  useEffect(() => {
    getSpecialtyNames(specialtySearchText).then((data) => {
      setSpecialtyDataOption(data);
    });
  }, []);

  useEffect(() => {
    if (specialityArray?.length > 0) {
      setIsSpecialtyEmpty(false);
    }
  }, [specialityArray]);

  const removeSpecialityChipHandler = (chip) => {
    setSpecialityArray(specialityArray.filter((list) => list != chip));
  };

  const handleSpecialtyVal = () => {
    if (!specialtyVal) return;
    if (specialtyVal && !specialityArray?.includes(specialtyVal)) {
      setSpecialityArray((prevState) => [...prevState, specialtyVal]);
    }
    setSpecialtyVal('');
  };

  useEffect(() => {
    setValue(filedManageAttendee.specialty.name, specialityArray);
  }, [specialityArray]);

  useEffect(() => {
    register(filedManageAttendee.specialty.name);
  }, []);

  return (
    <form>
      <div className="boxed-radio mx-n1 form_el">
        <DoctTypography variant="subtitle2" className="mt-0 mb-12px">
          Gender
        </DoctTypography>
        <DoctRadioGroup {...filedManageAttendee.gender} {...formProps} />
      </div>
      <span className="form_el d-inline-block">
        <DoctTextField {...filedManageAttendee.name} {...formProps} />
        <DoctTypography variant="caption1" className="text-grey-600">
          This name will be shown in Event Certificate.
        </DoctTypography>
      </span>
      <div className="form_el ">
        <LocationField
          inputProps={{
            country: {
              disabled: true,
            },
          }}
          {...formProps}
          columnLayout={{ country: 12, city: 6, state: 6 }}
          errors={errors}
          savedValue={defaultValue}
          valueAccessBy={'name'}
          className={'form_el form_el_gap_bottom'}
        />
      </div>

      <DoctTypography variant="subtitle2" className="mt-0">
        Contact Details
      </DoctTypography>
      <MobileWhatsAppForm
        {...formProps}
        errors={errors}
        columnLayout={{ mobile: 12, whatsapp: 12 }}
        className="form_el"
      />
      <DoctTextField {...filedManageAttendee.email} {...formProps} className="form_el" />
      <div className="mt-3 mb-2 d-flex align-items-center">
        <div style={{ width: '85%' }}>
          <DoctFreeSoloSearchInput
            control={control}
            placeholder="Select specialty*"
            id={filedManageAttendee.specialty.name}
            onChangeHandler={(val) => {
              if (val) {
                setIsSpecialtyEmpty(false);
              }
              setSpecialtyVal(val);
            }}
            onInputChangeHandler={(val) => {
              if (val) {
                setIsSpecialtyEmpty(false);
              }
              setSpecialtySearchText(val);
            }}
            name={filedManageAttendee.specialty.name}
            hideSearchIcon={true}
            value={specialtyVal}
            options={specialtyDataOption}
            isErrors={isSpecialtyEmpty}
            inputValue={specialtyVal?.value}
            validationRules={{
              required: "It's Required Field",
            }}
            onClearHandler={(e) => {
              if (specialityArray) {
                e.preventDefault();
                setSpecialtyVal();
                setValue(filedManageAttendee.specialty.name, []);
              }
            }}
          />
        </div>
        <div
          className="px-2"
          onClick={(e) => {
            e.preventDefault();
            handleSpecialtyVal();
          }}
        >
          <DoctIcon className="cursor-pointer" name="success" width="40" fill="#00A0C0" />
        </div>
      </div>
      {isSpecialtyEmpty && <p className="custom-validation-error">Its required</p>}
      {specialityArray.length != 0 && <div className="mt-1"></div>}
      <div className="mx-n1 d-flex flex-wrap">
        {specialityArray?.map((speciality, index) => (
          <span key={index} className="mx-1 mb-1 mt-1">
            <DoctChip
              title={speciality}
              onCloseHandler={() => {
                removeSpecialityChipHandler(speciality);
              }}
            />
          </span>
        ))}
      </div>
      <DoctTextField {...filedManageAttendee.designation} {...formProps} className="form_el mt-1" />
      <DoctTextField
        {...filedManageAttendee.organization}
        {...formProps}
        className="form_el form_el_gap_bottom mt-3"
      />

      <DoctTypography variant="subtitle2" className="mt-0">
        Other Details (Optional)
      </DoctTypography>
      <DoctTextField {...filedManageAttendee.licenseNo} {...formProps} className="form_el" />
      <DoctTextField {...filedManageAttendee.memberId} {...formProps} className="form_el" />
    </form>
  );
}

export default memo(FormManageAttendees);
