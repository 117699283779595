import React, { useEffect } from 'react';
import { DoctAnimationLoading } from '@doct-react/app';
import { useHardReloadForCachedApp } from '../../helper/helperFunction';
import { AppRoutes } from '../../routes';
import ToastWrapper from '../ToastWrapper';
import TrackApp from '../TrackApp';
// import { Monitoring } from 'react-scan/monitoring';
// import { useLocation, useParams } from 'react-router';
function App() {
  useEffect(() => {
    useHardReloadForCachedApp();
  }, []);

  // const location = useLocation();
  // const pathname = location.pathname;
  // const params = useParams();

  return (
    <TrackApp>
      <div id="loader" className="app-loader">
        <DoctAnimationLoading />
      </div>
      {/* {process.env.REACT_APP_ENV === 'development' && (
        <Monitoring
          apiKey="QHCv4tll_j6AH6aUa1y7HGePKtchPVj7"
          url="https://monitoring.react-scan.com/api/v1/ingest"
          false
          params={params}
          pathname={pathname}
        />
      )} */}
      <AppRoutes />
      <ToastWrapper />
    </TrackApp>
  );
}

export default App;
