//Temp Comment Razorpay integration code

// import * as ROUTE from '../../../routes/constant';
import logo from '../../../assets/icons/brand_logo.svg';
const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const initializeRazorpayCheckout = async (paymentInfo, prepObjectForFormData) => {
  const options = {
    key: 'rzp_test_O7C0KqQ9I1FNmL', // Your Razorpay test key
    order_id: paymentInfo.paymentInformation?.token,
    name: 'Docthub',
    description: 'Purchase Description',
    image: logo,
    callback_url: prepObjectForFormData.callbackUrl,
    prefill: {
      name: prepObjectForFormData?.subscriberBillingInformation?.billingName,
      email: prepObjectForFormData?.subscriberBillingInformation?.emailId,
    },
    theme: {
      color: '#0091AF',
    },
    handler: function (response) {
      // Replace "razorpay" in redirectUrl with the actual payment ID from the response
      const redirectUrl = paymentInfo.callbackUrl.replace('razorpay', response.razorpay_payment_id);

      // Redirect to the updated URL
      window.location.href = redirectUrl;
    },
    modal: {
      ondismiss: function () {},
      // Note :- remove hot reload
    },
  };

  const rzp = new window.Razorpay(options);

  // Bug 5012: Only one mail should be sent. solution implementation.
  // Debounce mechanism to prevent multiple redirects
  let paymentFailedTriggered = false;

  rzp.on('payment.failed', function (response) {
    if (paymentFailedTriggered) return; // Exit if already triggered
    paymentFailedTriggered = true;

    try {
      const redirectUrl = paymentInfo.callbackUrl.replace(
        'razorpay',
        response.error.metadata.payment_id,
      );

      if (redirectUrl && response.error.reason === 'payment_failed') {
        // Redirect to the updated URL
        window.location.href = redirectUrl;
      }
    } catch (error) {
      paymentFailedTriggered = false;
    }
  });

  rzp.open();
};

const createSubscriptionRequest = async (url, prepObjectForFormData, token) => {
  const response = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(prepObjectForFormData),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to create subscription');
  }

  return response.clone().json();
};

export async function confirmSubscription(
  setShowLoader,
  storedBundle,
  id,
  prepObjectForFormData,
  token,
  loading,
) {
  setShowLoader(true);
  try {
    const isRazorpayLoaded = await loadRazorpayScript();
    if (!isRazorpayLoaded) {
      throw new Error('Failed to load Razorpay script');
    }
    let subscriptionUrl;
    if (!storedBundle) {
      subscriptionUrl = `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${id}/subscription`;
    } else {
      subscriptionUrl = `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${storedBundle.id}/bundle/${storedBundle.bundleId}/subscription?payby=razorpay`;
    }

    const data = await createSubscriptionRequest(
      subscriptionUrl,
      prepObjectForFormData,
      token,
      loading,
    );
    const paymentInfo = data?.paymentInformation;

    // Load Razorpay script and initialize payment
    // Execute the below condition in an async function
    (async () => {
      try {
        if (data?.paymentInformation?.payby !== 'cashfree') {
          const scriptLoaded = await loadRazorpayScript();
          if (!scriptLoaded) {
            throw new Error('Failed to load Razorpay script');
          }
          await initializeRazorpayCheckout(data, prepObjectForFormData);
        }
      } catch (error) {
        // Handle the error here
      }
    })();
    if (data?.paymentInformation?.payby === 'cashfree') {
      window.location.href = paymentInfo?.redirectUrl;
    }
  } catch (error) {
    throw new Error('Error during subscription');
  } finally {
    setShowLoader(false);
  }

  return;
}

//Cashfree integration code

// export async function confirmSubscription(storedBundle, id, prepObjectForFormData, token) {
//   try {
//     if (!storedBundle) {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${id}/subscription?payby=cashfree`,
//         {
//           method: 'POST',
//           body: JSON.stringify(prepObjectForFormData),
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//         },
//       );
//       if (response?.ok) {
//         const data = await response?.clone().json();
//         window.location.href = data?.paymentInformation?.redirectUrl;
//       }
//     } else {
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${storedBundle.id}/bundle/${storedBundle.bundleId}/subscription?payby=cashfree`,
//         {
//           method: 'POST',
//           body: JSON.stringify(prepObjectForFormData),
//           headers: {
//             'Content-Type': 'application/json',
//             Authorization: `Bearer ${token}`,
//           },
//         },
//       );
//       if (response?.ok) {
//         const data = await response?.clone().json();
//         window.location.href = data?.paymentInformation?.redirectUrl;
//       }
//     }
//   } catch (error) {
//     throw new Error(error);
//   }
// }
